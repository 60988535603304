<template>
    <evento-form
        acao="INSERIR"
        @salvar="inserir($event)"
        @atualizarErros="erros = $event"
        :cancelar="cancelar"
        :erros="erros"
        :evento="evento"
        :nivel="nivel"
        :eventoPaiId="eventoPaiId"
        :eventoPaiBuscaCnpj="pbcnpj"
    ></evento-form>
</template>

<script>
import EventosServices from './services';
import EventoForm from './EventoForm.vue';

export default {
    components: {
        EventoForm,
    },

    data() {
        return {
            erros: [],
            evento: null,
        };
    },

    computed: {
        nivel() {
            return parseInt(this.$route.query.nivel);
        },

        eventoPaiId() {
            return this.$route.query.id;
        },

        pbcnpj() {
            return this.$route.query.pbcnpj === 'true';
        },
    },

    methods: {
        cancelar() {
            if (this.nivel == 0) {
                this.$router.push({ name: 'Checkins_Eventos' });
            } else {
                this.toDetalhar(this.eventoPaiId, false);
            }
        },

        inserir(eventoInserido) {
            this.$store.dispatch('addRequest');
            EventosServices.inserir(eventoInserido).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Evento',
                        detail: 'Evento inserido com sucesso',
                        life: 3000,
                    });
                    if (this.nivel == 0) {
                        this.toDetalhar(response.data, true);
                    } else {
                        this.toDetalhar(eventoInserido.eventoPaiId, false);
                    }
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(eventoId, inserido) {
            if (inserido) {
                this.$store.dispatch('setAtualizar', true);
            }

            if (this.nivel == 0) {
                this.$router.push({
                    name: 'Checkins_Eventos_Detalhar',
                    params: {
                        id: eventoId,
                    },
                });
            }

            if (this.nivel == 1) {
                this.$router.push({
                    name: 'Checkins_Eventos_Detalhar',
                    params: {
                        id: eventoId,
                    },
                    query: {
                        view: 'eventos',
                    },
                });
            }
        },
    },
};
</script>
